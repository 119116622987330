import React from 'react'
import { graphql } from 'gatsby'
import DefaultPage from '../components/default-page'

export default ({ data }) => {
  const page = data.page.edges

  return (
    <DefaultPage title={'Contact Us'} page={page[0].node.childMarkdownRemark} />
  )
}

export const query = graphql`
  query {
    page: allFile(filter: { name: { eq: "page-contact" } }) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
            }
            fields {
              slug
            }
            html
          }
        }
      }
    }
  }
`
